export function convertApiErrorCodeToCamelCase(code: string) {
  let parts = code.split("_");

  let camelCaseStr = parts[0].toLowerCase();

  for (let i = 1; i < parts.length; i++) {
    if (parts[i].length > 0) {
      camelCaseStr +=
        parts[i][0].toUpperCase() + parts[i].slice(1).toLowerCase();
    }
  }

  return camelCaseStr;
}
